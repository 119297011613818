<template>
  <loading-spinner v-if="state.loading" />
  <div v-else>
    <the-header-card @refresh="loadInvitesInfo">
      <va-button flat @click="openDangerModal" color="danger">
        {{ $t("buttons.delete") }}
      </va-button>
    </the-header-card>
    <div class="row row-equal">
      <div class="flex xs12 xl12">
        <invite-information-tile />
      </div>
      
    </div>

    <div class="row row-equal">
      <div class="flex xs12">
      </div>
    </div>

    
  </div>
  <danger-confirm-modal
    :is-open="state.showDangerModal"
    :title="$t('invites.deletion')"
    :message="$t('invites.messageModal')"
    :button-validate-message="$t('invites.deletion')"
    @cancel="cancelDelete"
    @validate="deleteInvite"
  />
</template>

<script setup>
import { inject, reactive, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import TheHeaderCard from "@/components/TheHeaderCard.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import DangerConfirmModal from "@/components/modal/DangerConfirmModal.vue";
import { callDeleteInvite } from "@/api/identity";
import InviteInformationTile from "@/components/invites/InviteInformationTile.vue";


const route = useRoute();
const router = useRouter();
const store = useStore();
const handleError = inject("handleError");


const state = reactive({
  loading: false,
  invite: computed(() => store.state.identity.invite),
  showDangerModal: false,
  currentResourceSwitch: "organizations",
});

const loadInvitesInfo = async () => {
  state.loading = true;
  // get user data
  const inviteId = route.params.id;
  await store.dispatch("identity/fetchInvite", inviteId);
  state.loading = false;
  console.log(store.state.identity)
};
const openDangerModal = () => {
  state.showDangerModal = true;
};
const cancelDelete = () => {
  state.showDangerModal = false;
};
const deleteInvite = async () => {
  const inviteId = route.params.id;
  console.log(inviteId)
  if (inviteId) {
    try {
      await callDeleteInvite(inviteId);

      // remove it from the state
      store.state.identity.user = null;
      router.push({ path: "/admin/identity/invites" });
    } catch (err) {
      if (err instanceof Error || typeof err === "string") {
        handleError(err?.response?.data || err);
      }
    }
    state.showDangerModal = false;
  }
};
onMounted(async () => {
  await loadInvitesInfo();

});
</script>
