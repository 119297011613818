<template>
  <base-tile :title="$t('invites.info')" i18nPrefix="users" :rows="state.rows">
    <!-- ID -->
    <template #[state.readUserIdSlot]>
      <td v-if="state.id" class="p-2">
        <span>{{ state.id }} </span>
        <button-clipboard-copy :field="$t('invites.id')" :value="state.id" />
      </td>
    </template>

    <!-- EMAIL -->
    <template #[state.email]>
      <td v-if="state.email" class="p-2">
        <span>{{ state.email }} </span>
        <button-clipboard-copy
          :field="$t('invites.email')"
          :value="state.email"
        />
      </td>
    </template>
  </base-tile>
</template>

<script setup>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import BaseTile from "@/components/BaseTile.vue";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import { printDate } from "@/utils";

const { t } = useI18n();
const store = useStore();

const state = reactive({
  rows: computed(() => {
    const users = store.state?.identity?.user;
    console.log(users)
    return [
      {
        label: t("invites.id"),
        value: users?.id
      },
      {
        label: t("invites.createdAt"),
        value: printDate(users?.createdAt)
      },
      {
        label: t("invites.email"),
        value: users?.email
      },
      {
        label: t("invites.purpose"),
        value: users?.purpose
      },
      {
        label: t("invites.ProductName"),
        value: users?.productName

      },
      {
        label: t("invites.url"),
        value: users?.url
      },
      {
        label: t("invites.deliveryCount"),
        value: users?.deliveryCount
      },
      {
        label: t("invites.acceptedAt"),
        value: printDate(users?.lastSubmittedAt)
      }
    ];
  }),
  readUserIdSlot: `${t("users.id")}-value`,
  readUserEmailSlot: `${t("users.email")}-value`
});
</script>
